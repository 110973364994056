<template>
  <ContentWrapper :title="labels.LBL_TEAM_MEMBERS" back>
    <template slot="actions">
      <el-button
        size="small"
        type="success"
        @click="submitForm('executiveForm')"
      >
        <i class="el-icon-check mr-1"></i>
        {{ labels.LBL_UPDATE }}
      </el-button>
    </template>

    <el-form
      v-loading="loading"
      ref="executiveForm"
      :model="executive"
      :rules="rules"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item :label="labels.LBL_PHOTO">
            <UploadImage v-model="executive.photo" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="salutation" :label="labels.LBL_SALUTATIONS">
            <el-select
              v-model="executive.salutation"
              placeholder="Select Salutation"
            >
              <el-option
                v-for="item in salutations"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="first_name" :label="labels.LBL_FIRST_NAME">
            <el-input v-model="executive.first_name"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="last_name" :label="labels.LBL_LAST_NAME">
            <el-input v-model="executive.last_name"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="email" :label="labels.LBL_EMAIL">
            <el-input v-model="executive.email" type="email"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="phone" :label="labels.LBL_CONTACT_NO">
            <el-input v-model="executive.phone"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="address" :label="labels.LBL_ADDRESS">
            <el-input v-model="executive.address"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item
            prop="highest_education"
            :label="labels.LBL_HIGHEST_EDU"
          >
            <el-input v-model="executive.highest_education"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="designation_id" :label="labels.LBL_DESIGNATION">
            <el-select
              v-model="executive.designation_id"
              :placeholder="labels.PH_SELECT_DESIGNATION"
            >
              <el-option
                v-for="item in designationList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="`${labels.LBL_FB} ${labels.LBL_URL}`">
            <el-input v-model="executive.facebook_link"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="`${labels.LBL_TWI} ${labels.LBL_URL}`">
            <el-input v-model="executive.twitter_link"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="`${labels.LBL_LNKIN} ${labels.LBL_URL}`">
            <el-input v-model="executive.linkedin_link"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="voice" :label="labels.LBL_ABOUT">
            <el-input
              type="textarea"
              :rows="4"
              v-model="executive.voice"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </ContentWrapper>
</template>

<script>
  import { labels, rules } from "@/common";
  import { mapGetters, mapState } from "vuex";
  import Executive from "@/models/executive";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper";
  import UploadImage from "@/components/UploadImage";

  export default {
    name: "EditMember",

    props: {
      memberId: {
        type: [String, Number],
        default: "",
      },
    },

    components: {
      ContentWrapper,
      UploadImage,
    },

    data() {
      return {
        labels,

        executive: new Executive(),

        rules,
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        designationList: (state) => state.executive.designations,
        executiveDetail: (state) => state.executive.executiveDetail,
      }),

      ...mapGetters({
        salutations: "executive/salutationDDL",
      }),
    },

    watch: {
      memberId: {
        immediate: true,
        handler(nv) {
          this.loadExecutiveDetail(nv);
        },
      },

      executiveDetail: {
        handler(nv) {
          this.setExecutiveDetail(nv);
        },
      },
    },

    mounted() {
      this.loadDesinations();
      this.loadSalutations();
    },

    methods: {
      loadExecutiveDetail(id) {
        this.$store.dispatch("executive/getExecutiveDetail", id);
      },

      loadDesinations() {
        this.$store.dispatch("executive/getDesignations");
      },

      loadSalutations() {
        this.$store.dispatch("executive/getSalutations");
      },

      setExecutiveDetail(detail) {
        this.executive = {
          id: detail.id,
          photo: detail.photo_url,
          salutation: detail.salutation,
          first_name: detail.first_name,
          last_name: detail.last_name,
          email: detail.email,
          phone: detail.phone,
          address: detail.address,
          highest_education: detail.highest_education,
          designation_id: detail.designation.id,
          can_login: !!detail.can_login,
          facebook_link: detail.facebook_link ? detail.facebook_link : null,
          twitter_link: detail.twitter_link ? detail.twitter_link : null,
          linkedin_link: detail.linkedin_link ? detail.linkedin_link : null,
          voice: detail.voice,
        };
      },

      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.dispatch("executive/updateExecutive", this.executive);
          }
        });
      },
    },
  };
</script>

<style></style>
